import React from 'react';
import PushNotifications from './PushNotifications';
import './Engagement.scss';
import ContextRules from './ContextRules';
import Contents from './Contents';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { MAIN_CLASS } from '../constants';

const Engagement: React.FC = () => {
  const { flags } = useFeatureFlag();

  if (!flags['tx_show_analytics_engagement']) return false;

  return (
    <div className={MAIN_CLASS}>
      <PushNotifications />
      <ContextRules />
      <Contents />
    </div>
  );
};

export default Engagement;
