import './MerchantAndOfferStatus.scss';
import React from 'react';
import { FlightTooltip } from '@flybits/design-system';
import { STATUS_MAP } from './constants';
import {
  MERCHANT_VERIFICATION_STATUSES,
  OFFER_VERIFICATION_STATUSES,
  OFFER_STATUSES,
  STATUS_ENTITIES,
} from 'pages/MerchantPortal/merchant-portal.types';

const CLASS_MAIN = 'verification-status';
const CLASSES = {
  ICON: `${CLASS_MAIN}__icon`,
};

type MerchantAndOfferStatusProps = {
  type: STATUS_ENTITIES;
  status: MERCHANT_VERIFICATION_STATUSES | OFFER_VERIFICATION_STATUSES | OFFER_STATUSES;
  showTooltip?: boolean;
  customTooltipText?: string;
};

export default function MerchantAndOfferStatus(props: MerchantAndOfferStatusProps) {
  const { type, status, showTooltip, customTooltipText } = props;

  const statusInfo = STATUS_MAP[type];
  const title = statusInfo?.[status]?.title || '';
  const description = statusInfo?.[status]?.description || '';

  return title ? (
    <FlightTooltip description={customTooltipText || description} direction="bottom" isEnabled={showTooltip}>
      <div className={`${CLASS_MAIN} ${CLASSES.ICON}-${status}`}>
        <i className={`${CLASSES.ICON}`}></i>
        {title}
      </div>
    </FlightTooltip>
  ) : (
    status
  );
}
