import React from 'react';
import './Breadcrumbs.scss';

const CLASS_MAIN = 'breadcrumbs';
const CLASSES = {
  WRAPPER: `${CLASS_MAIN}__wrapper`,
  ITEM: `${CLASS_MAIN}__item`,
  ITEM_CONTENT: `${CLASS_MAIN}__item__content`,
  ICON: `${CLASS_MAIN}__item__icon`,
  NUMBER: `${CLASS_MAIN}__item__number`,
};

type BreadCrumbItems = {
  icon: JSX.Element | null;
  text: string;
  forceOutline?: boolean;
};
interface BreadCrumbProps extends React.ComponentProps<'div'> {
  items: BreadCrumbItems[];
  selectedStep?: number;
}

const Breadcrumbs: React.FC<BreadCrumbProps> = ({ items, selectedStep = 1 }) => {
  return (
    <div className={CLASS_MAIN}>
      <ul className={CLASSES.WRAPPER}>
        {items.map(({ icon, text, forceOutline }, index) => (
          <li key={index} className={`${CLASSES.ITEM}`}>
            <div className={`${CLASSES.ITEM_CONTENT} ${selectedStep === index + 1 ? 'selected' : ''}`}>
              {icon && <span className={`${CLASSES.ICON} ${forceOutline ? 'outline' : ''}`}>{icon}</span>}{' '}
              {!icon && <span className={`${CLASSES.NUMBER}`}>{index + 1}</span>}
              {text}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Breadcrumbs;
