import React, { useCallback, useState } from 'react';
import './RegisterMerchant.scss';
import MerchantForm from 'components/MerchantPortal/MerchantForm/MerchantForm';
import Breadcrumbs from 'components/Shared/Breadcrumbs/Breadcrumbs';
import { FlightButton } from '@flybits/design-system';
import MerchantReview from 'components/MerchantPortal/MerchantReview/MerchantReview';
import MerchantSubmitted from 'components/MerchantPortal/MerchantSubmitted/MerchantSubmitted';
import { MerchantFormValues, MerchantsRouteParams } from '../merchant-portal.types';
import UnsavedPrompt from 'components/Shared/UnsavedPrompt/UnsavedPrompt';
import useMerchantCategoriesMock from 'hooks/useMerchantCategoriesMock';
import { useHistory, useParams } from 'react-router-dom';
import merchantService from 'services/api/merchants.api';
import { mapMerchantDataToFormValues, mapMerchantFormValuesToData } from '../merchant-portal.helpers';
import { useThunkDispatch as useDispatch } from 'hooks/reduxHooks';
import { MERCHANT_FORM_INIT_VALS } from '../merchant-portal.constants';
import { sleep } from 'helpers/common.helper';
import { useQueryClient } from '@tanstack/react-query';

const REGISTER_MERCHANT = 'register-merchant';
const CLASSES = {
  HEADER: `${REGISTER_MERCHANT}__header`,
  INFO: `${REGISTER_MERCHANT}__header__info`,
  TITLE: `${REGISTER_MERCHANT}__header__title`,
  GUTTER: `${REGISTER_MERCHANT}__header__gutter`,
  SUBTITLE: `${REGISTER_MERCHANT}__header__subtitle`,
  BODY: `${REGISTER_MERCHANT}__body`,
  BREADCRUMBS: `${REGISTER_MERCHANT}__body__breadcrumbs`,
  CONTAINER: `${REGISTER_MERCHANT}__body__container`,
};

// NOTE: These are EXAMPLE icons. Please update to match with designs when implementing
const BREADCRUMB_ITEMS = [
  { icon: null, forceOutline: true, text: 'Create Merchant profile' },
  { icon: null, forceOutline: true, text: 'Review and confirm' },
];

const initialValues: MerchantFormValues = MERCHANT_FORM_INIT_VALS;
const RegisterMerchant = function () {
  const history = useHistory();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [selectedStep, setSelectedStep] = useState(1);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { merchantCategories } = useMerchantCategoriesMock();
  const [merchantData, setMerchantData] = useState<MerchantFormValues>(initialValues);
  const { pid: projectId } = useParams<MerchantsRouteParams>();
  const [isMerchantFormDirty, setMerchantFormDirty] = useState(false);
  const handleOnFormSubmit = useCallback((values: MerchantFormValues) => {
    setSelectedStep(2);
    setMerchantData(values);
  }, []);
  const handleOnReviewSubmit = useCallback(async () => {
    setIsSubmitted(false);
    setSelectedStep(3);
    try {
      const merchant = await merchantService.createMerchant(mapMerchantFormValuesToData(merchantData));
      setMerchantData(mapMerchantDataToFormValues(merchant));
      await queryClient.invalidateQueries({ queryKey: ['merchants'] });
      await sleep(1000);
      setIsSubmitted(true);
    } catch (error) {
      setIsSubmitted(false);
      setSelectedStep(2);
      dispatch({
        type: 'SHOW_SNACKBAR',
        payload: {
          title: 'Error',
          content: `The Merchant ${merchantData.name} could not be created. ${error}`,
          type: 'error',
        },
      });
    }
  }, [merchantData, dispatch, queryClient]);
  const handleResetForm = useCallback(() => {
    setMerchantData(initialValues);
    setSelectedStep(1);
    setIsSubmitted(false);
  }, []);

  return (
    <>
      <div className={REGISTER_MERCHANT}>
        <header className={CLASSES.HEADER}>
          <FlightButton
            iconLeft="clear"
            theme="link"
            ariaLabel="navigate back to merchant list"
            onClick={() => history.push(`/project/${projectId}/merchants`)}
          />
          <div className={CLASSES.TITLE}>Register a Local Merchant</div>
          <div className={CLASSES.GUTTER}></div>
        </header>
        {selectedStep !== 3 ? (
          <div className={CLASSES.BODY}>
            <section className={CLASSES.BREADCRUMBS}>
              <Breadcrumbs items={BREADCRUMB_ITEMS} selectedStep={selectedStep} />
            </section>
            {selectedStep === 1 ? (
              <section className={CLASSES.CONTAINER}>
                <MerchantForm
                  data={merchantData}
                  categories={merchantCategories ?? []}
                  onSubmit={handleOnFormSubmit}
                  setDirty={setMerchantFormDirty}
                />
              </section>
            ) : (
              <section className={CLASSES.CONTAINER}>
                <MerchantReview
                  data={merchantData}
                  onBackClick={() => setSelectedStep(1)}
                  onSubmit={handleOnReviewSubmit}
                />
              </section>
            )}
          </div>
        ) : (
          <MerchantSubmitted onReset={handleResetForm} data={merchantData} isSubmitted={isSubmitted} />
        )}
      </div>
      <UnsavedPrompt when={isMerchantFormDirty && selectedStep < 3} unblockPaths={['/register-merchant']} />
    </>
  );
};

export default RegisterMerchant;
