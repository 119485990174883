import './MerchantSlideout.scss';
import React, { useCallback, useMemo, useState } from 'react';
import { Merchant, MerchantsRouteParams, STATUS_ENTITIES } from 'pages/MerchantPortal/merchant-portal.types';
import { TSlidingSidePanelProps } from 'components/ExperienceCanvas/types';
import MemoizedSlidingSidePanel from 'components/ExperienceCanvas/SlidingSidePanel/SlidingSidePanel';
import { FlightButton, getIcon } from '@flybits/design-system';
import MerchantAndOfferStatus from '../MerchantAndOfferStatus/MerchantAndOfferStatus';
import IconDiscardChanges from 'components/Shared/Icons/IconDiscardChanges';
import { sleep } from 'helpers/common.helper';
import { ConfirmationModalTypes } from 'components/Shared/shared.types';
import ConfirmationModal, { ConfirmationModalProps } from 'components/Shared/ConfirmationModal/ConfirmationModal';
import { useHistory, useParams } from 'react-router-dom';
import { useAppSelector as useSelector } from 'hooks/reduxHooks';

const MERCHANT_SLIDEOUT = 'merchant-slideout';

const MERCHANT_FIELD = `${MERCHANT_SLIDEOUT}__field`;
const MERCHANT_FIELD_CLASSES = {
  NAME: `${MERCHANT_FIELD}__name`,
  VAL: `${MERCHANT_FIELD}__val`,
};

const getFieldValue = (fieldKey: string, merchant: Merchant) => {
  switch (fieldKey) {
    case 'address':
      return (
        <div
          className={MERCHANT_FIELD_CLASSES.VAL}
        >{`${merchant.addressLine}, ${merchant.addressCity}, ${merchant.addressState}, ${merchant.addressZipCode}`}</div>
      );
    case 'verificationStatus':
      return (
        <MerchantAndOfferStatus
          type={STATUS_ENTITIES.MERCHANT_VERIFICATION_STATUS}
          status={merchant.verificationStatus}
          showTooltip
        />
      );
    default:
      return <div className={MERCHANT_FIELD_CLASSES.VAL}>{merchant[fieldKey as keyof Merchant] as string}</div>;
  }
};

interface MerchantFieldProps extends React.ComponentProps<'div'> {
  fieldKey: string;
  fieldName: string;
  merchant: Merchant;
}

const MerchantField: React.FC<MerchantFieldProps> = ({ fieldKey, fieldName, merchant }) => (
  <div className={MERCHANT_FIELD}>
    <div className={MERCHANT_FIELD_CLASSES.NAME}>{fieldName}:</div>
    {getFieldValue(fieldKey, merchant)}
  </div>
);

const MERCHANT_SECTION = `${MERCHANT_SLIDEOUT}__section`;
const MERCHANT_SECTION_CLASSES = {
  HEADER: `${MERCHANT_SECTION}__header`,
  FIELDS: `${MERCHANT_SECTION}__fields`,
  LOGO: `${MERCHANT_SECTION}__logo`,
};

interface MerchantSectionProps extends React.ComponentProps<'div'> {
  header: string;
  fields: { [key: string]: string };
  merchant: Merchant;
}

const MerchantSection: React.FC<MerchantSectionProps> = ({ header, fields, merchant }) => {
  return (
    <div className={MERCHANT_SECTION}>
      <div className={MERCHANT_SECTION_CLASSES.HEADER}>{header}</div>
      <div className={MERCHANT_SECTION_CLASSES.FIELDS}>
        {Object.keys(fields).map((field) => (
          <MerchantField key={field} fieldKey={field} fieldName={fields[field]} merchant={merchant} />
        ))}
      </div>
    </div>
  );
};

const MERCHANT_PROFILE_FIELDS = {
  name: 'Merchant name',
  id: 'Merchant ID',
  category: 'Category',
  phoneNumber: 'Phone number',
  address: 'Address',
};

const MERCHANT_CONTACT_FIELDS = {
  contactName: 'Contact person',
  contactPhoneNumber: 'Phone number',
  contactEmail: 'Email',
};

const MERCHANT_STATUS_FIELDS = {
  verificationStatus: 'Verification Status',
  notes: 'Note',
};

const DELETE_MERCHANT = `${MERCHANT_SLIDEOUT}__delete`;
const DELETE_MERCHANT_CLASSES = {
  INFO: `${DELETE_MERCHANT}__info`,
  TITLE: `${DELETE_MERCHANT}__info__title`,
  DESC: `${DELETE_MERCHANT}__info__desc`,
};

interface DeleteMerchantProps extends React.ComponentProps<'div'> {
  merchant: Merchant;
  disabled?: boolean;
  setShowSlideout: React.Dispatch<React.SetStateAction<boolean>>;
}

const DeleteMerchant: React.FC<DeleteMerchantProps> = ({ merchant, disabled, setShowSlideout }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isDeleting, setDeleting] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const handleDeleteMerchant = useCallback(async () => {
    setShowSlideout(false);

    setDeleting(true);
    // MOCK: here goes the API call
    await sleep(2000);
    setDeleting(false);

    closeModal();
  }, [setShowSlideout]);

  const confirmationDialogProps: ConfirmationModalProps = useMemo(
    () => ({
      isOpen: isModalOpen,
      closeModal,
      theme: ConfirmationModalTypes.DISCARD,
      icon: <IconDiscardChanges />,
      title: `Remove ${merchant.name}`,
      description: 'To permanently delete this merchant, enter the merchant name below. This action cannot be undone.',
      primaryAction: {
        value: 'Delete Merchant',
        onClickHandler: handleDeleteMerchant,
      },
      secondaryAction: {
        value: 'Cancel',
        onClickHandler: closeModal,
      },
      isLoading: isDeleting,
      confirmationInput: {
        placeholderText: 'Enter merchant name',
        matchText: merchant.name,
      },
    }),
    [merchant.name, isModalOpen, isDeleting, handleDeleteMerchant],
  );

  return (
    <>
      <div className={DELETE_MERCHANT}>
        <div className={DELETE_MERCHANT_CLASSES.INFO}>
          <div className={DELETE_MERCHANT_CLASSES.TITLE}>Delete merchant</div>
          <div className={DELETE_MERCHANT_CLASSES.DESC}>
            {disabled
              ? 'If you wish to remove a merchant from the system, please contact the Flybits Solutions team for assistance.'
              : 'This action cannot be undone and you will lose access to the profile'}
          </div>
        </div>
        <FlightButton
          type="warn"
          theme="secondary"
          label="Delete merchant"
          ariaLabel="Delete merchant"
          disabled={disabled}
          onClick={openModal}
        />
      </div>
      <ConfirmationModal {...confirmationDialogProps} />
    </>
  );
};

const MERCHANT_SLIDEOUT_CLASSES = {
  CONTAINER: `${MERCHANT_SLIDEOUT}__container`,
  CONTENT: `${MERCHANT_SLIDEOUT}__container__content`,
  SECTIONS: `${MERCHANT_SLIDEOUT}__container__content__sections`,
  LOGO: `${MERCHANT_SLIDEOUT}__container__content__logo`,
};

interface MerchantSlideoutProps extends React.ComponentProps<'div'> {
  merchant: Merchant;
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const MerchantSlideout: React.FC<MerchantSlideoutProps> = ({ merchant, show, setShow }) => {
  const history = useHistory();
  const { pid: projectId } = useParams<MerchantsRouteParams>();
  const isFBAdmin = useSelector((state) => state.auth.user?.level && state.auth.user.level >= 90);
  const merchantSlideoutProps = useMemo<TSlidingSidePanelProps>(
    () => ({
      show,
      headerInfo: {
        mainTitle: 'View Merchant Information',
        showCloseButton: true,
        headerAction: {
          label: 'Edit merchant',
          handler: () => history.push(`/project/${projectId}/merchants/${merchant.id}/edit`),
        },
      },
      showFooter: false,
      footerInfo: {
        secondaryActionHandler: () => setShow(false),
      },
      className: MERCHANT_SLIDEOUT,
    }),
    [show, history, projectId, merchant.id, setShow],
  );

  return (
    <MemoizedSlidingSidePanel {...merchantSlideoutProps}>
      <div className={MERCHANT_SLIDEOUT_CLASSES.CONTAINER}>
        <div className={MERCHANT_SLIDEOUT_CLASSES.CONTENT}>
          <div className={MERCHANT_SLIDEOUT_CLASSES.SECTIONS}>
            <MerchantSection header="Merchant Profile" fields={MERCHANT_PROFILE_FIELDS} merchant={merchant} />
            <MerchantSection header="Merchant Contact Person" fields={MERCHANT_CONTACT_FIELDS} merchant={merchant} />
            <MerchantSection
              header="Merchant Verification Status"
              fields={MERCHANT_STATUS_FIELDS}
              merchant={merchant}
            />
          </div>
          <div className={MERCHANT_SLIDEOUT_CLASSES.LOGO}>{getIcon('merchant', {})}</div>
        </div>
        <DeleteMerchant merchant={merchant} disabled={!isFBAdmin} setShowSlideout={setShow} />
      </div>
    </MemoizedSlidingSidePanel>
  );
};

export default MerchantSlideout;
